.orders{
    max-height: 75vh;
    overflow-y: auto;
}

.order{
    background: #eee;
    margin-top: 15px;
    padding: 10px;
    min-height: 160px;
    border-radius: 6px;  
    cursor: pointer;
}