@import '../../styles/mixins.scss';
.statBox {
    height: 60px;
    min-width: 170px;
    background-color: #f4f4f4;
    color: black;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
    border-radius: 20px;
    padding: 0 5px;

    p {
        font-size: 20px;
        font-weight: 500;
    }

    @include Responsive("tab") {
        p {
            font-size: 16px;
        }
    }
}

.imagePrev {
    height: 80px !important;
    width: 80px !important;
    object-fit: cover;
    overflow: hidden;
    border-radius: 1px;
    border: 1px dashed #c4c4c4;
}

.imagePrev.big {
    height: 120px !important;
    width: 120px !important;
}

.unauthorizedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    max-width: 600px;
    margin: 40px auto;
    box-shadow: 1px 2px 6px #eee;

    h2 {
        font-size: 30px;
        font-weight: 600;
    }

    img {
        height: min(400px, 70vw);
        margin-top: 20px;
    }

    p {
        margin-top: 50px;
        font-size: 18px;
        opacity: 0.8;
        text-align: center;
    }
}

.uploader {
    height: 80px;
    width: 80px;
    background: #f5f5f5;
    border: 1px dashed #c4c4c4;
    border-radius: 2px;
    overflow: hidden;
}

.filters {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none !important;
    }

}