@import '../../styles/mixins.scss';

.auth {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  background: url(../../assets/png/background.png) center/cover no-repeat;
  display: grid;
  place-items: center;
}

.authCard {
  min-height: 80vh;
  width: min(700px,99vw);
  border-radius: 10px;
  box-shadow: 1px 2px 6px -2px rgb(0 0 0 / 80%);
  background: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  img.logo{
    height: 70px;
  }

  h1 {
    color: white;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 2%;
    font-size: 2.2rem !important;
    font-weight: 500 !important;
  }

  @include Responsive("tab") {
    margin-bottom: 40px;
    height: auto;
    padding-bottom: 20px;
    border-radius: 0px;

    h1 {
     font-size: 1.5rem !important;
    }
  }

  input {
    border: 1px solid rgb(188 188 188);
    border-width: 0px;
    border-bottom-width: 1px;
    height: 50px;
    width: 80%;
    margin-top: 10px;
    font-size: 1rem;
    background: transparent;
    padding-left: 5px;
    color: white;
    &::placeholder{
      color: #ffffff60;
    }
    &:focus{
      border-width: 0px !important;
      border-bottom-width: 3.5px !important;
      outline-width: 0px !important;
      outline: none;
      border-color: white;
    }

    @include Responsive("tab") {
      width: 80%;
    }
  }

  button {
    height: 40px;
    border-radius: 5px;
    background-color: #ff5722;
    color: white;
    margin-top: 2%;
    margin-bottom: 2%;
    border: none;
    outline-width: 0;
    width: 80%;
    font-size: 1.1rem;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.4);

    @include Responsive("tab") {
      width: 80%;
    }
  }

  .select {
    width: 60%;
    height: 40px;
    border-radius: 6px;
    margin-top: 10px;

    @include Responsive("tab") {
      width: 80%;
    }
  }

  p {
    opacity: 0.8;
    font-weight: 400;
    margin: 10px 0 0 0;
    color:white;
  }
}

.authCard__left {
  background: linear-gradient(to top, rgb(0 251 175 / 40%) 20%, transparent);
  flex: 0.5;
  display: grid;
  place-items: center;

  @include Responsive("tab") {
    display: none
  }

  ;
}

.authCard__left>img {
  height: 52vh;
  width: auto;
}

