@import './styles/mixins.scss';

@include Opacity;
@include Sizing;

.itallic {
  font-style: italic;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.m-1 {
  margin: 5px !important;
}

.mx-1 {
  margin: 0 5px;
}

.mx-auto {
  margin: 0 auto;
}

.mt-1 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-1 {
  padding-top: 3px !important;
}

.pl-2 {
  padding-left: 10px !important;
}

.pl-4 {
  padding-left: 20px !important;
}

.px-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pb-1 {
  padding-bottom: 5px;
}

.ml-1 {
  margin-left: 5px !important;
}

.mr-1 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-50 {
  margin-left: 50px;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.c-primary {
  color: var(--primary-color);
}

.c-error {
  color: var(--error) !important;
}

.c-white {
  color: white !important;
}

.c-black {
  color: #393939 !important;
}

.flex1 {
  flex: 1;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-full {
  width: 100% !important;
}

.w-100 {
  width: 100px !important;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px !important;
}

.w-300 {
  width: 300px !important;
}

.w-50p {
  width: 50% !important;
}

.w-60p {
  width: 60%;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.h-35 {
  height: 35px !important;
}

.h-40 {
  height: 40px !important;
}

.h-180 {
  height: 180px !important;
}

.w-180 {
  width: 180px !important;
}

.h-120 {
  height: 120px !important;
}

.w-120 {
  width: 120px !important;
}


.ant-radio-button-wrapper {
  font-size: 17px !important;
  font-weight: 500 !important;
}

.ant-radio-button-wrapper-checked,
.ant-btn-primary {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-switch-checked {
  background-color: #13b11c !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  overflow: hidden;
  white-space: break-spaces;
  min-width: 200px !important;
}

.tracking-tight {
  letter-spacing: -0.5px;
}

.tracking-wide {
  letter-spacing: 0.5px;
}

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

.bg-red {
  background-color: var(--error);
}

.container {
  max-width: 1700px;
  margin: 0 auto;
}

.bg-selected {
  background: #20b49410 !important;
}

.link {
  color: var(--primary-color) !important;
  font-weight: 600 !important;
}

.bg-checklists {
  background: rgb(228, 248, 255) !important;
}

.bg-gray {
  background-color: #eee !important;
}

.top-sticky {
  position: sticky !important;
  top: 0px;
  z-index: 9;
}

.shadow{
  box-shadow: 1px 6px 11px #eee;
}