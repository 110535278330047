button.button {
    border: none;
    outline-width: 0px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    background-color: var(--primary-color);
    color: white;
    font-size: 17px;
    font-weight: 500;

    &:hover,
    &:focus {
        outline-width: none;
        transform: scale(1.02);
    }

    &.danger {
        background-color: var(--error) !important;
    }

    &.bordered {
        background-color: transparent;
        border: 1px solid #d0d0d0;
        color: #d0d0d0;
    }
}


button.tabSwitch--btn {
    height: 40px;
    width: 150px;
    background-color: #f8f8f8;
    color: darkslategray;
    line-height: 90%;

    &.selected {
        background-color: var(--primary-color);
        color: white
    }
}

button.tabSwitch--btn.small {
    width: 50px !important;
}

.addNew__btn {
    background: var(--primary-color) !important;
    border-radius: 50px !important;
    padding: 0 20px !important;
}