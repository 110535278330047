@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

:root {
  --primary-color: #255ff4;
  --purple: #4a154b;
  --skyblue: #5a325a;
  --error: rgb(253, 68, 68);
  --light: #ebf2ff;
}
body {
  margin: 0;
}
* {
  font-family: "Lato", sans-serif;
  transition: all 0.2s ease-in;
}
*::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}
*::-webkit-scrollbar-track {
  background-color: #eee;
}
*::-webkit-scrollbar-thumb {
  background: #ccc;
}
*::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

button {
  cursor: pointer;
}
button:hover,
button:focus {
  transform: scale(1.02);
}

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-15px);
  }
}

h1 {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
}
p {
  margin-bottom: 0 !important;
}
main.ant-layout-content {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-pagination-item-active {
  font-weight: 500 !important;
  background: var(--primary-color) !important;
  border-color: transparent !important;
}
.ant-pagination-item-active > a {
  color: white !important;
}
.react-colorful {
  width: 100% !important;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid white !important;
}
a.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
  color: black;
}
.fc .fc-button-primary {
  background-color: var(--primary-color) !important;
  border-color: white !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: transparent !important;
}
