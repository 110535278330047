@import '../../styles/mixins.scss';

table.userData__table {
  width: 100%;
  font-size: 16px;
  max-width: 1700px;
  margin: 0 auto;

  @include Responsive("tab") {
    font-size: 13px;
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
  }
}

table.userData__table thead {
  height: 50px;
  background: rgba(238, 238, 238, 0.589);
  font-weight: 600;
}

.userData__table td {
  padding-left: 5px;
  min-width: 80px;
}

tr {
  height: 65px;

  &.hoverable {
    &:hover {
      cursor: pointer;
      background-color: #20b49410 !important;
    }
  }
}

.permissions__table {
  min-width: 100%;
  display: flex;
  overflow-x: auto;
  // border: 1px solid rgb(160, 160, 160);
  border-radius: 2px;
  border-right-width: 0px;

  @include Responsive("tab") {
    overflow-x: auto;
    white-space: nowrap;
    font-size: 12px;
  }

  .permission__col {
    flex: 1;
    text-align: center;
    min-width: 19%;

    // border-right: 1px solid rgb(160, 160, 160);

    h2 {
      height: 50px;
      padding-top: 10px;
      font-weight: 600;
    }

    h3 {
      margin-bottom: 0px !important;
      padding-bottom: 10px;
      background-color: rgb(244, 244, 244);
    }

    @include Responsive("tab") {
      min-width: 70%;
      h2,
      h3 {
        font-size: 15px !important;
      }
    }

    .permission__box {
      height: 80px;
      display: grid;
      place-items: center;
      text-align: left;

      // border-top: 1px solid rgb(160, 160, 160);
      @include Responsive("tab") {
        padding-left: 10px;
      }
    }
  }
}

.permissions__nav {
  font-size: 16px;
  margin: 20px auto;
  overflow-x: auto;

  @include Responsive("tab") {
    white-space: nowrap;
  }
}