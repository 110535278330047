@import '../../styles/mixins.scss';

.profile {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  place-items: center;
}
.profile__card {
  height: 350px;
  width: 400px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 1px 2px 6px #eee;
  @include Responsive("tab"){
    width: 100%;
  }
}
