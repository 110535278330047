@import '../../styles/mixins.scss';

.layout {
  overflow: hidden !important;
  height: 100vh;
  width: 100vw;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.menu-header {
  height: 120px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
}

.menu-header>p {
  font-size: 1.3rem;
  font-weight: 500;
}

.ant-menu,
.ant-layout-sider {
  background-color: white !important;
}

.ant-menu-item-selected {
  background-color: var(--light) !important;
}

.ant-layout-sider {
  min-width: 250px !important;
  max-width: 250px !important;
  width: 250px !important;
  overflow-y: auto;
  border-right: 1px solid #F0F0F1 !important;
}

.ant-layout-sider-collapsed {
  min-width: 50px !important;
  max-width: 50px !important;
  width: 50px !important;
}

.content {
  margin: 0px !important;
  padding: 35px 24px 24px 24px !important;
  // padding:24px !important;
  min-height: 280px !important;

  @include Responsive("tab") {
    margin: 0px 0px !important;
    padding: 10px !important;
    min-height: 100vh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

.content.noPadding {
  padding: 0px !important;
}

.header {
  position: fixed;
  top: 10px;
  height: max-content;
  right: 24px;
  width: fit-content;
  z-index: 99;
}