.divider {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.divider__left,
.divider__right {
  border-top: 1.5px solid #e6e6e6;
  margin: 0 2%;
  flex: 1;
}
