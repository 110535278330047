.dashboard__card {
    min-height: 250px;
    min-width: 300px;
    background: white;
    border-radius: 2px;
    padding: 15px;
    letter-spacing: 0.5px;
    h3{
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: -0.5px;
    }
    .float-right{
        float: right;
        p.subTitle{
            color:rgb(143, 143, 143);
            font-weight: 500 !important;
            font-size: 17px;
            letter-spacing: -0.5px;
        }
        h1.count{
            font-size: 50px !important;
            line-height: 130%;
        }
    }
}