.avatar {
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 6px;
  flex-shrink: 0;
}

.avatar > p {
  font-size: 17px;
}
