.greenTick {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: rgb(1, 177, 1);
  color: white;
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redCross{
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: red;
  color: white;
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: center;
}
