$breakpoints: ("tab": 1000px,
    "mobile": 500px,
) !default;

$margins:20;

@mixin Responsive($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin Sizing {
    @for $i from 10 through 30 {
        @if $i % 2==0 {
            .fs-#{$i} {
                font-size: #{$i}#{'px'} !important;
            };
        }
    }
}

@mixin Opacity {
    @for $i from 0 through 100 {
        @if $i%10==0 {
            .opacity-#{$i} {
                opacity: #{$i/100};
            }
            .rounded-#{$i}{
                 border-radius: #{$i}#{'px'} !important;
            }
        }
    }
}