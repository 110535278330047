@import '../../styles/mixins.scss';

table.assetReg__table {
    width: 100%;
    font-size: 11px;
    letter-spacing: -0.5px;
    font-size: 15px;
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;

    thead {
        height: 50px;
        background: rgba(238, 238, 238, 0.589);
        font-weight: 600;
        border-top: 1px solid #eee;
    }
    
    tr {
        height: 65px;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        &.hoverable{
          &:hover{
              cursor: pointer;
              background-color: #20b49410 !important;
          }  
        }
    }
    td,th{
        border-left: 1px solid #eee;
        padding: 0 10px;
        min-width: 90px;
        max-width: 300px;
    }
}

.tableRow__img{
        border-radius: 2px;
        margin: 2px 5px;
        height: 70px;
        object-fit: contain;
        width: 70px;
}

.bg-light{
    background-color: #20b49420 !important;
}

.logs__column{
    height: 480px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
        z-index: 0;
}

.message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 14px;
    padding: 6px;
    position: relative;
    margin: 8px 0;
    max-width: 85%;
    word-wrap: break-word;
    background: #e1ffc7;
    border-radius: 5px 0px 5px 5px;
    float: right;
}

.message.mine{
    float: left;
    margin-left: 8px;
}

.message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0;
    right: -10px;
}

.message.mine:after{
    left:-10px !important;
    border-width: 0px 10px 10px 0;
    border-color: transparent #e1ffc7 transparent transparent;
    top: 0;
    left: -10px;
}

.metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
}

.metadata .time {
    color: rgba(0, 0, 0, .45);
    font-size: 11px;
    display: inline-block;
}

.message > .chat-profile__pic {
    position: absolute;
    height: 20px;
    width: 20px;
    background: red;
    color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 2px;
    top: -8px;
    left: -5px;
}

.message.mine > .chat-profile__pic{
    background-color: #1164a3;
    right:-5px !important;
    left:95% !important
}

.logs-bg{
    background-color: #efefef;
}
.nologs--text{
    color: #7f7f7f;
    font-weight: 600;
    font-size: 16px;
    height: 100%;
    display: grid;
    place-items: center;
    p{
        width: 100%;
        text-align: left;
    }
}