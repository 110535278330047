.checklist__card {
    width: 93%;
    padding: 10px;
    margin: 15px auto;
    border-radius: 6px;
    border: 1px solid #eee;
    background: #f0f0f0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 210px;
    &.modal {
      height:max-content !important;
    }
    &.selected{
      border: 2px solid #00a0e4;
      box-shadow: 1px 2px 6px 2px #d9d9d9;
    }
    h3{
        font-size: 20px;
    font-weight: 600;
    }
    p.task {
        opacity: 0.7;
        font-size: 15px;
        font-weight: 500;
        max-width:90%;
    }
}

p.task_status {
  font-size: 12px;
  font-weight: 600;
  margin-top: 2px;
  letter-spacing: -0.7px;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  &.green{
      color:#69cf74;
  }
  &.red{
      color:#f07070;
  }
  &.blue{
      color:#00a0e4;
  }
}

.checklist__modal{
  .ant-modal-content{
  // background-color: rgb(228, 248, 255) !important;
  border-radius: 10px !important;
  }
  .rounded-10{
    .ant-select-selector{
      border-radius: 10px !important;
    }
  }
}

.tag {
    padding: 0.2rem 0.5rem;
    margin-right: -1.5rem;
    position: relative;
    background: #00a0e4;
    border-radius: 0.3rem 0 0 0.3rem;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    height: 24px;
    color: #ffffff;
    white-space: nowrap;
    margin-bottom: unset !important;
  }

  .tag::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 12px solid #000000;
    position: absolute;
    top: -12px;
    right: 0px;
  }
  .tag.green {
    background: #69cf74;
  }
  .tag.red {
    background: #f07070;
  }
  .tag.blue {
    background: #00a0e4;
  }
 
.checklist__stat {
    background: white;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 10px;
    padding: 5px 2px;
}