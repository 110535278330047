.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  overflow: none;
  position: relative;
}

.logout__btn {
  color: white;
  background: red;
  padding: 2px 10px;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  border: none;
  outline: none;
  font-size: 1.02rem;
  font-weight: 500;
}

.home button {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 1.02rem;
  font-weight: 500;
}

.home__keysContainer {
  min-height: 100px;
  width: 300px;
  text-align: center;
  background: #0f52ff10;
  border-radius: 6px;
  box-shadow: 1px 1px 1px #f2f2f2f2;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px auto;
}
