@import '../../styles/mixins.scss';

.cancelSave__buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.cancelSave__buttons > button{
    width: 46%;
    height: 35px;
    border-radius: 6px;
    font-size: 16px;
}
.radio-tabs__configManager{
    @include Responsive("tab"){
        display: flex !important;
        flex-direction: column;
    }
    label{
        @include Responsive("tab"){
            height: fit-content;
            margin-top: 5px;
        }
    }
}